<div class="inline-flex w-full bg-slate-200 border border-xlines">
  <input
    type="text"
    class="text-xtitles text-xs w-full p-2.5 placeholder:text-xplaceholders placeholder:text-xs ring-0 border-0 focus:border-0 focus:ring-transparent"
    [formControl]="dateControl"
    (input)="onInputChange($event)"
    placeholder="dd/mm/yyyy"
    maxlength="10"
  />
</div>
