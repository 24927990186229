import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { FrontendWorkOrder } from '../../types/order/V2/frontend-workorder';

interface WorkOrders {
  workOrders: FrontendWorkOrder[];
}
@Injectable({
  providedIn: 'root',
})
export class WorkorderService {
  private api = env.apiUrl;

  constructor(private http: HttpClient) {}

  // xtension user

  /** Obtener todas las ordenes de todos los tenants */
  public getAllOrdersFromAllTenants(): Observable<FrontendWorkOrder[]> {
    return this.http.get<FrontendWorkOrder[]>(
      `${this.api}/tenant/workorder/all`
    );
  }

  /** Obtener todas las ordenes de un tenant que estén enviadas, confirmadas o realizadas */
  public getAllOrdersFromOneTenant(
    rut_tenant: string
  ): Observable<FrontendWorkOrder[]> {
    return this.http.get<FrontendWorkOrder[]>(
      `${this.api}/tenant/workorder/${rut_tenant}`
    );
  }

  /** clientB2b */

  /** Enviar una orden a Odoo */
  public insertOTinOdoo(workOrders: WorkOrders) {
    return this.http.post(`${this.api}/workorder_v2/sendToOdoo`, workOrders);
  }

  /** Obtener todas las ordenes de un tenant sin importar su estado */
  public getOTs(PK: FrontendWorkOrder['PK']): Observable<FrontendWorkOrder[]> {
    return this.http.get<FrontendWorkOrder[]>(`${this.api}/workorder_v2/${PK}`);
  }

  /** Obtener una orden de un tenant */
  public getOT(
    PK: FrontendWorkOrder['PK'],
    SK: FrontendWorkOrder['SK']
  ): Observable<FrontendWorkOrder> {
    return this.http.get<FrontendWorkOrder>(
      `${this.api}/workorder_v2/${PK}/${SK}`
    );
  }

  /** Guardar una nueva orden */
  public addOT(OT: FrontendWorkOrder) {
    return this.http.post(`${this.api}/workorder_v2`, OT);
  }
  /** Actualizar una orden */
  public updateOT(
    PK: FrontendWorkOrder['PK'],
    SK: FrontendWorkOrder['SK'],
    OT: string
  ) {
    return this.http.put(`${this.api}/workorder_v2/${PK}/${SK}`, OT);
  }

  /** Buscar una OT por cliente final */
  public getOrdersByClient(
    PK: FrontendWorkOrder['PK'],
    rut: string
  ): Observable<FrontendWorkOrder[]> {
    return this.http.get<FrontendWorkOrder[]>(
      `${this.api}/workorder_v2/byClient/${PK}/${rut}`
    );
  }

  /** Buscar ordenes por rango de fecha */
  public getOrdersByDate(
    PK: FrontendWorkOrder['PK'],
    startDateStr: string,
    endDateStr: string
  ): Observable<FrontendWorkOrder[]> {
    return this.http.get<FrontendWorkOrder[]>(
      `${this.api}/workorder_v2/byDate/${PK}/${startDateStr}/${endDateStr}`
    );
  }

  /** Buscar ordenes por operativo */
  public getOrdersByOperative(
    PK: FrontendWorkOrder['PK'],
    operative: FrontendWorkOrder['header']['operativo']
  ): Observable<FrontendWorkOrder[]> {
    return this.http.get<FrontendWorkOrder[]>(
      `${this.api}/workorder_v2/byOperative/${PK}/${operative}`
    );
  }
}
