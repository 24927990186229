import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from 'src/environments/environment';
import { SalesOrder } from '../../types/order/V1/salesOrder';

@Injectable({
  providedIn: 'root',
})
export class PrintPDFService {
  private api = env.apiUrl;

  constructor(private http: HttpClient) {}

  public printPDF(
    PK: SalesOrder['PK'],
    SK: SalesOrder['SK'],
    printType: 'xtension' | 'client'
  ) {
    const body = {
      PK: PK,
      SK: SK,
    };
    return this.http.post(`${this.api}/pdf/${printType}`, body, {
      responseType: 'blob',
    });
  }
}
