import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Tab, TabType } from '../interfaces/tabs.interface';
import { getTypeOfFormGroup } from '../helpers/getFormGroup';

@Injectable({
  providedIn: 'root',
})
export class TabConfigService {
  private tabs: Tab[] = [];
  activeTabIndex = 0;
  addMenuOpen = false;

  getTabsData(): Tab[] {
    return this.tabs;
  }

  setTabsData(tabs: Tab[]): void {
    this.tabs = tabs;
  }

  async addNewTab(tabType: TabType, active: boolean, canDelete: boolean) {
    const formGroup = (await getTypeOfFormGroup(tabType)) as FormGroup;

    if (this.tabs.length < 4) {
      const newTab: Tab = {
        label: tabType,
        content: `Este es el objeto del tipo ${tabType}`,
        active: active,
        canDelete: canDelete,
        form: formGroup,
      };

      this.tabs.push(newTab);
      this.activeTabIndex = this.tabs.length - 1;
    }
    this.addMenuOpen = false;
    const firstTab = this.tabs[0];
    this.activeTabIndex = this.tabs.indexOf(firstTab);

    return formGroup;
  }

  deleteTab(index: number) {
    //Eliminación del index dentro del arreglo
    this.tabs.splice(index, 1);

    //Si el index eliminado es el activo, se activa el anterior
    if (this.activeTabIndex >= this.tabs.length) {
      this.activeTabIndex = this.tabs.length - 1;
    }
  }

  // Setear y retornar el valor de la combinación entre tipo de lente, material y tratamiento
  getLensesValue(
    index: number,
    lensType: string,
    material: string,
    treatment: string
  ): string {
    return `${index + 1}.- ${lensType}: ${material}, ${treatment}`;
  }
}
