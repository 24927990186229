<nav class="bg-white shadow-sm select-none cursor-default">
  <div class="p-4">
    <div
      class="flex items-center lg:justify-between md:justify-between sm:justify-between xs:justify-center"
    >
      <ul class="flex cursor-default">
        <li
          class="flex font-semibold text-sm space-x-1 lg:block md:block sm:block xs:hidden"
        >
          <span>Estado de la Orden</span>
        </li>
      </ul>

      <ol
        class="inline-flex items-center justify-center font-semibold text-center text-xsubtitles bg-transparent rounded-sm space-x-0"
      >
        <li
          [ngClass]="[
            'flex items-center  p-2',
            !order
              ? 'bg-xblue text-slate-50 duration-300 rounded-sm'
              : 'text-xblue bg-slate-50'
          ]"
        >
          <span
            class="  "
            [ngClass]="[
              'flex items-center justify-center w-5 h-5 mr-1 text-xs border rounded-full shrink-0',
              !order ? 'border-slate-50' : ' border-xblue'
            ]"
          >
            1
          </span>
          <span class="text-xs sm:inline-flex sm:ml-2">Nuevo</span>
          <svg
            aria-hidden="true"
            class="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            ></path>
          </svg>
        </li>
        <li
          [ngClass]="[
            'flex items-center  p-2',
            order && order.estado == 'Guardado'
              ? 'bg-xblue text-slate-50 duration-300 rounded-sm'
              : 'text-xblue bg-slate-50'
          ]"
        >
          <span
            class="  "
            [ngClass]="[
              'flex items-center justify-center w-5 h-5 mr-1 text-xs border rounded-full shrink-0',
              order && order.estado == 'Guardado'
                ? 'border-slate-50'
                : ' border-xblue'
            ]"
          >
            2
          </span>
          <span class="text-xs sm:inline-flex sm:ml-2">Guardada</span>
          <svg
            aria-hidden="true"
            class="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            ></path>
          </svg>
        </li>
        <li
          [ngClass]="[
            'flex items-center  p-2',
            order && order.estado == 'Enviado'
              ? 'bg-xblue text-slate-50 duration-300 rounded-sm'
              : 'text-xblue bg-slate-50'
          ]"
        >
          <span
            class="  "
            [ngClass]="[
              'flex items-center justify-center w-5 h-5 mr-1 text-xs border rounded-full shrink-0',
              order && order.estado == 'Enviado'
                ? 'border-slate-50'
                : ' border-xblue'
            ]"
          >
            3
          </span>
          <span class="text-xs sm:inline-flex sm:ml-2">Enviada</span>

          <svg
            aria-hidden="true"
            class="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            ></path>
          </svg>
        </li>

        <li
          [ngClass]="[
            'flex items-center  p-2',
            order && order.estado == 'Confirmado'
              ? 'bg-xblue text-slate-50 duration-300 rounded-sm'
              : 'text-xblue bg-slate-50'
          ]"
        >
          <span
            class="  "
            [ngClass]="[
              'flex items-center justify-center w-5 h-5 mr-1 text-xs border rounded-full shrink-0',
              order && order.estado == 'Confirmado'
                ? 'border-slate-50'
                : ' border-xblue'
            ]"
          >
            4
          </span>
          <span class="text-xs sm:inline-flex sm:ml-2">Confirmada</span>

          <!-- <svg
            aria-hidden="true"
            class="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            ></path>
          </svg> -->
        </li>
        <!--         
        <li
          [ngClass]="[
            'flex items-center  p-2',
            order && order.state == 'Realizado'
              ? 'bg-xblue text-slate-50 duration-300 rounded-sm'
              : 'text-xblue bg-slate-50'
          ]"
        >
          <span
            class="  "
            [ngClass]="[
              'flex items-center justify-center w-5 h-5 mr-1 text-xs border rounded-full shrink-0',
              order && order.state == 'Realizado'
                ? 'border-slate-50'
                : ' border-xblue'
            ]"
          >
            5
          </span>
          <span class="text-xs sm:inline-flex sm:ml-2">Realizada</span>
        </li> -->
      </ol>
    </div>
  </div>
</nav>
