import { Component } from '@angular/core';
import { Armazon, armazones } from '../../types/global-types/armazones';

@Component({
  selector: 'x-autocomplete',
  templateUrl: './autocomplete.component.html',
  standalone: false,
})
export class AutocompleteComponent {
  options: Armazon[] = armazones;
  filteredOptions: Armazon[] = [];
  search = '';
  dropdownOpen = false; // Estado del dropdown

  filterOptions() {
    //Parseamos el termino a minuscula
    const term = this.search.toLowerCase();
    //Filtramos las opciones con el termino
    this.filteredOptions = this.options.filter(
      (option) =>
        option.codigo.toLowerCase().includes(term) ||
        option.descripcion.toLowerCase().includes(term)
    );
  }

  selectOption(option: Armazon) {
    this.search = `${option.codigo} - ${option.descripcion}`;
    this.filteredOptions = []; // Cierra el desplegable
  }

  // Muestra el dropdown
  showDropdown() {
    this.filteredOptions = this.options; // Muestra todas las opciones inicialmente
    this.dropdownOpen = true;
  }
}
