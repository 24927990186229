import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'x-checkbox',
  templateUrl: './checkbox.component.html',
  standalone: false,
})
export class CheckboxComponent {
  @Input() label = '';
  @Input() formControlName = '';
  @Input() name = '';
  @Input() id = '';
}
