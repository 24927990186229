<div class="relative w-full">
  <!-- Input y Label -->
  <div class="inline-flex w-full bg-xbackground border border-xlines">
    <div class="flex justify-start items-center px-2.5 py-2.5 w-40">
      <label for="Armazon" class="ml-2 mr-2 text-xs font-semibold text-xtitles"
        >Armazón</label
      >
    </div>
    <input
      type="text"
      id="Armazon"
      name="Armazon"
      placeholder="Código o descripción"
      [ngClass]="[
        'text-xtitles text-xs w-full p-2 placeholder:text-xplaceholders placeholder:text-xs ring-0 border-0 focus:border-0 focus:ring-transparent'
      ]"
      [(ngModel)]="search"
      (focus)="showDropdown()"
      (input)="filterOptions()"
    />
  </div>

  <!-- Dropdown Options -->
  <ul
    *ngIf="dropdownOpen && filteredOptions.length > 0"
    class="absolute z-10 left-0 w-full bg-white border border-gray-300 rounded-sm shadow-lg overflow-y-auto max-h-40"
  >
    <li
      *ngFor="let option of filteredOptions"
      (click)="selectOption(option)"
      class="block px-4 py-2 text-xtitles hover:bg-xblue hover:text-white cursor-pointer"
    >
      <div class="inline-flex space-x-3">
        <p class="font-bold text-xs w-40">{{ option.codigo }}</p>
        <p class="text-xs">
          {{ option.descripcion }}
        </p>
      </div>
    </li>
  </ul>
</div>
