import { Injectable, signal } from '@angular/core';
import { filter, forkJoin, map, switchMap } from 'rxjs';

import { Auth0ManagementService } from '../auth0/auth0-management.service';
import { Auth0UserData } from '../auth0/types/userAuth0';
import { TenantData } from './types/tenant-data';
import { ClientB2bService } from '../xtension-api-dynamo/client-b2b.service';
import { BranchSvC } from '../xtension-api-dynamo/branch.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  // Signal para la metadata del usuario
  userMetadata = signal<Auth0UserData | null>(null);
  // Signal para la data del tenant
  tenant_data = signal<TenantData | null>(null);

  constructor(
    private auth0ManagementSvc: Auth0ManagementService,
    private branchService: BranchSvC,
    private clientB2BService: ClientB2bService
  ) {
    this.loadTenantData();
  }

  // Carga y guarda la metadata del usuario
  private loadUserMetadata() {
    return this.auth0ManagementSvc.userData$.pipe(
      filter((userData) => !!userData?.user_metadata), // Asegúrate de que los datos existan
      map((userData: Auth0UserData) => {
        this.userMetadata.set(userData);

        return userData; // Retorna el valor de `tenant`
      })
    );
  }

  // Carga los datos del Tenant
  loadTenantData() {
    this.loadUserMetadata()
      .pipe(
        switchMap((tenantPK) =>
          forkJoin({
            client: this.clientB2BService.getClientB2B(
              'clientB2B',
              tenantPK.user_metadata.tenant
            ),
            branches: this.branchService.getBranches(
              `${tenantPK.user_metadata.tenant}_branch`
            ),
          }).pipe(
            map((responses) => {
              const userAuth0 = this.userMetadata();
              if (!userAuth0) {
                throw new Error('User metadata is not available');
              }
              return {
                pk: tenantPK.user_metadata.tenant,
                client: responses.client,
                branches: responses.branches,
                userAuth0, // Ahora sabemos que no es null
              };
            })
          )
        )
      )
      .subscribe({
        next: (tenantData: TenantData) => {
          this.tenant_data.set(tenantData); // Guarda los datos en el Signal
        },
        error: (err) => {
          console.error('Error loading tenant data:', err);
        },
      });
  }
}
