import { Component, Input } from '@angular/core';
import { DateFormaterService } from '../../services/formater-services/date-formater.service';
import { FrontendWorkOrder } from '../../types/order/V2/frontend-workorder';

@Component({
  selector: 'x-print-format',
  templateUrl: './print-format.component.html',
  styleUrls: ['./print-format.component.css'],
  standalone: false,
})
export class PrintFormatComponent {
  @Input() order!: FrontendWorkOrder;
  @Input() printType = '';

  constructor(public dateSvC: DateFormaterService) {}
}
