import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { StorageService } from 'src/app/shared/services/persistency/storage.service';
import { environment as env } from 'src/environments/environment';
import { Branch } from '../../types/order/V1/branch';

@Injectable({
  providedIn: 'root',
})
export class BranchSvC {
  private api = env.apiUrl;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  public getBranch(PK: Branch['PK'], SK: Branch['SK']): Observable<Branch> {
    return this.http.get<Branch>(`${this.api}/branch/${PK}/${SK}`);
  }

  public getBranches(PK: Branch['PK']): Observable<Branch[]> {
    return this.http.get<Branch[]>(`${this.api}/branch/${PK}`);
  }

  public addBranch(branch: string) {
    return this.http.post(`${this.api}/branch`, branch);
  }

  public updateBranch(PK: Branch['PK'], SK: Branch['SK'], branch: string) {
    return this.http.put(`${this.api}/branch/${PK}/${SK}`, branch);
  }

  public deleteBranch(PK: Branch['PK'], SK: Branch['SK']) {
    return this.http.delete(`${this.api}/branch/${PK}/${SK}`);
  }
}
