export type TipoMontaje =
  | 'Cerrado'
  | 'Semi expuesto'
  | 'Al aire (Tornillos)'
  | 'Al aire (Bujes)';

export interface Armazon {
  codigo: string;
  descripcion: string;
  precio: number;
  tipoMontaje: TipoMontaje;
  atributos?: {
    [key: string]: any;
  };
}

export const armazones: Armazon[] = [
  {
    codigo: 'EA 3158 5754 54 20 143',
    descripcion: 'Emporio Armani Acetato Azul con varillas negras traslúcidas',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'MTLS039 COL 02 147',
    descripcion: 'LOIS ACETATO CAFÉ',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'PH 2197 5182 58 18 145',
    descripcion: 'POLO BY RALPH LAUREAN ACETATO CAFE',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'TB1733 1197797',
    descripcion: 'Timberland Azúl Mate',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'CL1132 1204057',
    descripcion: 'Christian Lacroix Café Tortoise ',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'BEO1007 1203792',
    descripcion: 'Benetton Café Tortoise ',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'F8751 1223457',
    descripcion: 'D´Arezzo Negro Brillante ',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'RACE D417 1198117',
    descripcion: 'Race Negro Brillante',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'BEKO2000 1203297',
    descripcion: 'Benetton Rojo Brillante',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
  {
    codigo: 'L2284E 1199985',
    descripcion: ' Lacoste Azul Mate',
    precio: 50000,
    tipoMontaje: 'Cerrado',
  },
];
