import { Component, Input } from '@angular/core';
import { FrontendWorkOrder } from '../../types/order/V2/frontend-workorder';

@Component({
  selector: 'order-state-nav',
  templateUrl: './order-state-nav.component.html',
  standalone: false,
})
export class OrderStateNavComponent {
  @Input() order!: FrontendWorkOrder;
}
