import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'x-date-picker-input',
  standalone: false,

  templateUrl: './date-picker-input.component.html',
  styleUrl: './date-picker-input.component.css',
})
export class DatePickerInputComponent implements OnInit {
  dateControl = new FormControl('');
  @Output() dateChanged = new EventEmitter<string>(); // Emitimos la fecha al padre

  ngOnInit() {
    const today = new Date();
    const formattedDate = this.formatDate(today);
    this.dateControl.setValue(formattedDate);
    this.dateChanged.emit(formattedDate); // Emitimos la fecha por defecto
  }
  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  onInputChange(event: any) {
    const value = event.target.value.replace(/\D/g, ''); // Solo números
    let formattedValue = '';

    // Aplicar formato dinámico sin bloquear la entrada
    if (value.length > 2) {
      formattedValue += value.substring(0, 2) + '/';
    } else {
      formattedValue += value.substring(0, 2);
    }
    if (value.length > 4) {
      formattedValue += value.substring(2, 4) + '/';
    } else if (value.length > 2) {
      formattedValue += value.substring(2, 4);
    }
    if (value.length > 4) {
      formattedValue += value.substring(4, 8);
    }

    this.dateControl.setValue(formattedValue, { emitEvent: false }); // Evita loop infinito
    this.dateChanged.emit(formattedValue); // Emitimos al padre
  }
}
