<div class="modal" *ngIf="isOpen">
  <div
    [ngClass]="[
      'bg-white rounded-sm p-2 shadow-md mx-auto duration-300 animate-zoom-in',
      modalType == 'viewOrder' ? 'w-[700px]' : '  w-[500px] '
    ]"
  >
    <!--Botón para cerrar el modal-->
    <div class="text-right">
      <button
        (click)="close()"
        type="button"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-md text-sm p-1 ml-auto inline-flex items-center duration-300"
        data-modal-hide="popup-modal"
      >
        <svg
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
    <!--Contenido del modal -->
    <div
      [ngClass]="[
        'w-full flex-row justify-center items-center',
        modalType !== 'viewOrder' ? 'text-center pt-8 px-8' : 'text-left'
      ]"
    >
      <!-- SVG icon -->
      <ng-content *ngIf="modalType !== 'viewOrder'"></ng-content>
      <!--Titulo modal-->
      <h1>
        {{ titulo }}
      </h1>
      <!--Mensaje modal-->
      <h3 *ngIf="modalType !== 'viewOrder'" class="mb-5 text-xtitles">
        {{ mensaje }}
      </h3>
      <!-- Checkbox -->

      <!--Input - Modal type: Add to Odoo an existent Order-->
      <form [formGroup]="form" class="mb-5">
        <input
          *ngIf="modalType == 'addToOdooExistentOrder'"
          type="number"
          id="odoo_order_number"
          name="odoo_order_number"
          formControlName="odoo_order_number"
          placeholder="1234"
          [ngClass]="[
            'w-96 text-xs text-xtitles placeholder:text-xs placeholder:text-xplaceholders ',
            !form.controls['odoo_order_number'].valid
              ? 'ring-1 ring-red-500 border-0 focus:border-0 focus:ring-red-500'
              : 'ring-0 ring-transparent focus:ring-0 focus:ring-transparent border-stone-400  focus:border-stone-400'
          ]"
        />
        <div
          class="flex justify-center items-center"
          *ngIf="modalType == 'deleteOrder'"
        >
          <input
            type="checkbox"
            formControlName="checkbox"
            id="checkbox"
            name="checkbox"
            [ngClass]="[
              'form-checkbox h-5 w-5  cursor-pointer duration-300 focus:ring-0 focus-border-0',
              form.controls['checkbox'].valid
                ? ' text-xblue border'
                : ' text-red-500 border border-red-500'
            ]"
          />
          <label for="checkbox" class="ml-2 text-xs cursor-pointer"
            >{{ checkboxLabel }}
            <span
              [ngClass]="[
                'text-xs text-red-500',
                form.controls['checkbox'].valid ? 'hidden' : 'visible'
              ]"
              >*</span
            >
          </label>
        </div>
      </form>
      <!--Orden - Modal type: viewOrder-->
      <div class="container" *ngIf="modalType == 'viewOrder'">
        <!--Sección Order Xtension-->
        <div class="column">
          <section class="title">
            <div class="order-number">
              <h4>
                Orden de Trabajo:
                <span class="text-xblue">{{
                  numberFormatter(order.SK ?? '', order.header.clienteB2B.SK)
                }}</span>
              </h4>
            </div>
            <div class="client-order-number">
              <h4>
                Orden Interna:
                <span class="text-xblue">{{ order.header.orden_interna }}</span>
              </h4>
            </div>
            <div class="odoo-order-number inline-flex space-x-2">
              <h4>
                Odoo
                <span
                  *ngIf="this.form.controls['odoo_order_number'].invalid"
                  class="text-xs text-red-500"
                  >*</span
                >
                :
                <span *ngIf="order.odoo_id !== null" class="text-xblue">{{
                  order.odoo_id
                }}</span>

                <div
                  class="inline-flex w-20 bg-xbackground border-0"
                  *ngIf="
                    order.odoo_id == null ||
                    !order.odoo_id ||
                    order.odoo_id == undefined
                  "
                >
                  <form [formGroup]="form">
                    <input
                      type="number"
                      id="odoo_order_number"
                      name="odoo_order_number"
                      formControlName="odoo_order_number"
                      placeholder="1234"
                      [ngClass]="[
                        'text-xtitles text-xs w-full p-2 placeholder:text-xplaceholders placeholder:text-xs ring-0 border-b border-white  focus:ring-transparent focus:border-b focus:border-xblue',
                        !form.controls['odoo_order_number'].valid
                          ? 'ring-1 ring-red-500 border-0 focus:border-0 focus:ring-red-500'
                          : 'ring-0 border-0 focus:border-0 focus:ring-transparent'
                      ]"
                    />
                  </form>
                </div>
              </h4>
            </div>
            <div class="order-state">
              <h4>
                Estado:
                <span class="text-xblue">{{ order.estado }}</span>
              </h4>
            </div>
          </section>
          <section class="general-data">
            <table>
              <tr>
                <th>Fecha creación</th>
                <th>Sucursal</th>
                <th>Urgente</th>
                <th>Fecha entrega</th>
              </tr>
              <tr>
                <td>{{ dateSvC.dateFormater(1, order.fecha_creacion) }}</td>
                <td>{{ order.header.sucursal.data.nombre }}</td>
                <td>
                  {{ order.header.orden_express || 'No' }}
                </td>
                <td>
                  {{
                    order.fecha_entrega
                      ? dateSvC.dateFormater(1, order.fecha_entrega)
                      : ''
                  }}
                </td>
              </tr>
            </table>
          </section>
          <section class="data-client">
            <h4 class="bold">Datos Cliente</h4>
            <table>
              <tr>
                <th>Rut</th>
                <th>Nombre</th>
                <th>Teléfono</th>
              </tr>
              <tr>
                <td>{{ order.header.clienteB2B.data.rut }}</td>
                <td>{{ order.header.clienteB2B.data.nombre_fantasia }}</td>
                <td>{{ order.header.sucursal.data.telefono }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <th>Dirección</th>
              </tr>
              <tr>
                <td>{{ order.header.sucursal.data.email }}</td>
                <td>{{ order.header.sucursal.data.direccion }}</td>
              </tr>
            </table>
          </section>
          <section class="prescription">
            <h4 class="bold">Prescripción</h4>
            <p class="bold">
              Foco:
              <span class="bold">{{
                order.body.detalle_prescripcion.foco
              }}</span>
            </p>
            <div *ngFor="let o of order.body.detalle_prescripcion.prescripcion">
              <table class="table-prescription">
                <tr>
                  <th></th>
                  <th>Esfera</th>
                  <th>Cilindro</th>
                  <th>Eje</th>
                  <th>Altura</th>
                  <th>Adición</th>
                  <th colspan="2">Distancia Pupilar</th>
                </tr>
                <tr>
                  <td>OD</td>
                  <td>{{ o.rightEye.sph }}</td>
                  <td>{{ o.rightEye.cyl }}</td>
                  <td>{{ o.rightEye.axys }}</td>
                  <td>{{ o.rightEye.height }}</td>
                  <td>{{ o.rightEye.add }}</td>
                  <td rowspan="2">{{ o.pupillaryDistance }}</td>
                </tr>
                <tr>
                  <td>OI</td>
                  <td>{{ o.leftEye.sph }}</td>
                  <td>{{ o.leftEye.cyl }}</td>
                  <td>{{ o.leftEye.axys }}</td>
                  <td>{{ o.leftEye.height }}</td>
                  <td>{{ o.leftEye.add }}</td>
                </tr>
              </table>
            </div>
          </section>
          <section class="features">
            <h4 class="bold">Cristales y Montaje</h4>

            <table>
              <tr>
                <th>N°</th>
                <th>Lente</th>
                <th>Material</th>
                <th>Montaje</th>
                <th>Tratamiento</th>
                <th>Armazón</th>
                <th>Teñido</th>
              </tr>
              <tr *ngFor="let trabajo of order.body.trabajos; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ trabajo.type }}</td>
                <td>{{ trabajo.material }}</td>
                <td>{{ trabajo.assembly }}</td>
                <td>{{ trabajo.treatment }}</td>
                <td>{{ trabajo.frame }}</td>
                <td>{{ trabajo.tinted }}</td>
              </tr>
            </table>
          </section>
          <section class="observations">
            <h4 class="bold">Observaciones</h4>
            <div>
              <p>
                {{ order.body.observaciones || 'No hay observaciones.' }}
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!-- Botones -->
    <div
      class="inline-flex space-x-3 w-full items-center justify-center pt-2 pb-4"
    >
      <primary-button
        *ngFor="let boton of botones"
        type="submit"
        (click)="realizarAccion(boton)"
        [ngClass]="[
          boton == 'Añadir a pedido existente' || boton == 'Cancelar'
            ? 'bg-xblue text-white hover:bg-xblue-enfasis duration-300'
            : boton == 'Aceptar' ||
              boton == 'Guardar' ||
              boton == 'Enviar a Odoo' ||
              boton == 'Enviar' ||
              boton == 'Realizado' ||
              boton == 'Crear pedido nuevo'
            ? 'bg-xgreen text-white hover:bg-xgreen-enfasis duration-300'
            : boton == 'Eliminar'
            ? 'bg-red-500 text-white hover:bg-red-600  duration-300'
            : 'bg-xwhite text-black border border-black  duration-300 hover:bg-slate-50'
        ]"
      >
        <svg
          *ngIf="boton == 'Descargar'"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 32 32"
        >
          <path
            fill="#909090"
            d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z"
          />
          <path fill="#f4f4f4" d="M24.031 2H8.808v27.928h20.856V7.873z" />
          <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5z" />
          <path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077z" />
          <path
            fill="#464648"
            d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2.042 2.042 0 0 0 .647-.117a1.427 1.427 0 0 0 .493-.291a1.224 1.224 0 0 0 .335-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.409-.104a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
          />
          <path
            fill="#dd2025"
            d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.543 7.543 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216 14.216 0 0 0 1.658 2.252a13.033 13.033 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787 10.787 0 0 1-.517 2.434a4.426 4.426 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14.228 14.228 0 0 0-2.453.173a12.542 12.542 0 0 1-2.012-2.655a11.76 11.76 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.309 9.309 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.622 9.622 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a22.549 22.549 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
          />
          <path fill="#909090" d="M23.954 2.077V7.95h5.633z" />
          <path fill="#f4f4f4" d="M24.031 2v5.873h5.633z" />
          <path
            fill="#fff"
            d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2.042 2.042 0 0 0 .647-.117a1.428 1.428 0 0 0 .493-.291a1.224 1.224 0 0 0 .332-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.411-.105a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
          />
        </svg>
        <span>{{ boton }}</span>
      </primary-button>
    </div>
  </div>
</div>
