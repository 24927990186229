import {
  Component,
  Input,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { DateFormaterService } from '../../services/formater-services/date-formater.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalType } from './types/modal-component';
import { OrderNumberFormaterService } from '../../services/formater-services/order-number-formater.service';
import { FrontendWorkOrder } from '../../types/order/V2/frontend-workorder';

@Component({
  selector: 'x-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  standalone: false,
})
export class ModalComponent {
  form: FormGroup = {} as FormGroup;
  @Input() isOpen = false;
  isClosing = false;
  submitted = false;

  @Input() modalType: ModalType['type'];
  @Input() titulo = '';
  @Input() mensaje = '';
  @Input() checkboxLabel = '';
  @Input() order: FrontendWorkOrder = {} as FrontendWorkOrder;
  @Input() botones: string[] = [];
  @Output() accionRealizada = new EventEmitter();
  @Output() closeModal = new EventEmitter<void>();
  @Output() returnOdooNumber = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    public dateSvC: DateFormaterService,
    private fb: FormBuilder,
    public formaterSvC: OrderNumberFormaterService
  ) {
    this.form = this.fb.group({
      odoo_order_number: [],
      checkbox: [false, Validators.requiredTrue],
    });
  }

  get odoo_id(): string {
    return this.form.controls['odoo_order_number'].value;
  }

  get checkboxValue(): string {
    return this.form.controls['checkbox'].value;
  }

  close() {
    this.closeModal.emit();
  }

  realizarAccion(accion: string) {
    this.accionRealizada.emit(accion);
    if (this.modalType == 'addToOdooExistentOrder') {
      this.returnOdooNumber.emit(this.odoo_id);
    }
  }

  /** Formatear números de la tabla */
  numberFormatter(orderNumber: string, tenant: string) {
    return this.formaterSvC.numberFormatter(orderNumber, tenant);
  }
}
